<template>
    <form-modal
        ref="formModal"
        :title="title"
        size="xl">
        <template v-slot:form>
            <form>
                <api-mode-vuetable
                    ref="vuetable"
                    :fetch-data="fetch"
                    :fields="fields"
                    :per-page="perPage"
                    :additional-parameters="additionalParameters"
                    :sort-order="sortOrder">
                    <template slot="percentage" slot-scope="props">
                        {{ modal.break_count_total > 0 ? parseFloat(props.rowData.break_count / modal.break_count_total * 100).toFixed(2) : 0 }}%
                    </template>
                </api-mode-vuetable>
            </form>
        </template>
    </form-modal>
</template>

<script>
import RuptureService from '@src/services/dashboards/widgets/RuptureService.js'

import FormModal from '@/src/components/FormModal.vue'
import ApiModeVuetable from '@/src/components/ApiModeVuetable.vue'
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'
import dueDateTypes from '@src/mixins/dueDateTypes'
import filterVuetable from '@src/mixins/filterVuetable'

const i18nKey = 'DASHBOARDS'
const i18nCommon = 'COMMON'

export default {
    components: {
        FormModal,
        ApiModeVuetable,
    },
    mixins: [
        validationMixin,
        swalFeedback,
        dueDateTypes,
        filterVuetable,
    ],
    data() {
        return {
            i18nKey,
            i18nCommon,
            perPage: 10,
            additionalParameters: {},
            modal: {},
            title: '',
            sortOrder: [
                {
                    field: 'name',
                    direction: 'asc',
                },
            ],
            fields: [
                {
                    name: 'name',
                    title: this.getI18n('POS', 'TITLES.pos'),
                },
                {
                    name: 'visits_count',
                    title: this.getI18n(i18nKey, 'TITLES.pos_visited'),
                },
                {
                    name: 'break_count',
                    title: this.getI18n(i18nKey, 'TITLES.total_breaks'),
                },
                {
                    name: 'percentage',
                    title: this.getI18n(i18nKey, 'TITLES.percentage_rupture'),
                },
                {
                    name: 'product_name_more_rupture',
                    title: this.getI18n(i18nKey, 'TITLES.product_with_more_break'),
                },
            ]
        }
    },
    validations: {},
    computed: {
        i18nChainBreak() {
            return this.getI18n(i18nKey, 'TITLES.chain_break')
        },
    },
    methods: {
        showModal(data, parameters) {
            this.additionalParameters = {
                ...parameters,
                pos_chain_id: data.id,
            }
            this.modal = data;
            this.submitLoading = false
            this.title = `${this.i18nChainBreak}: ${this.modal.name}`
            this.$refs.formModal.show()
        },

        fetch(url, params) {
            return RuptureService.fetchVuetable('chain/listPos', params)
        },
    },
}
</script>
