<template>
    <form-modal
        ref="formModal"
        :title="title"
        size="xl">
        <template v-slot:form>
            <form>
                <api-mode-vuetable
                    ref="vuetable"
                    :fetch-data="fetch"
                    :fields="fields"
                    :per-page="perPage"
                    :additional-parameters="additionalParameters"
                    :sort-order="sortOrder">
                    <template slot="percentage" slot-scope="props">
                        {{ modal.break_count_total > 0 ? parseFloat(props.rowData.break_count / modal.break_count_total * 100).toFixed(2) : 0 }}%
                    </template>
                </api-mode-vuetable>
            </form>
        </template>
    </form-modal>
</template>

<script>
import RuptureService from '@src/services/dashboards/widgets/RuptureService.js'

import FormModal from '@/src/components/FormModal.vue'
import ApiModeVuetable from '@/src/components/ApiModeVuetable.vue'
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'
import dueDateTypes from '@src/mixins/dueDateTypes'
import filterVuetable from '@src/mixins/filterVuetable'

const i18nKey = 'DASHBOARDS'
const i18nCommon = 'COMMON'

export default {
    components: {
        FormModal,
        ApiModeVuetable,
    },
    mixins: [
        validationMixin,
        swalFeedback,
        dueDateTypes,
        filterVuetable,
    ],
    data() {
        return {
            i18nKey,
            i18nCommon,
            perPage: 10,
            additionalParameters: {},
            modal: {},
            title: '',
            sortOrder: [
                {
                    field: 'name',
                    direction: 'asc',
                },
            ],
            fields: [
                {
                    name: 'external_code',
                    title: this.getI18n(i18nCommon, 'external_code'),
                },
                {
                    name: 'name',
                    title: this.getI18n('POS', 'TITLES.pos'),
                },
                {
                    name: 'channel_name',
                    title: this.getI18n('POS', 'TITLES.channel'),
                },
                {
                    name: 'chain_name',
                    title: this.getI18n('POS', 'TITLES.chain'),
                },
                {
                    name: 'long_name',
                    title: this.getI18n(i18nCommon, 'address'),
                },
                {
                    name: 'regional_name',
                    title: this.getI18n('POS', 'TITLES.regional'),
                },
                {
                    name: 'flag_name',
                    title: this.getI18n('POS', 'TITLES.flag'),
                },
                {
                    name: 'category_name',
                    title: this.getI18n('POS', 'TITLES.category'),
                },
            ]
        }
    },
    validations: {},
    computed: {
        i18nPosAudited() {
            return this.getI18n(i18nKey, 'TITLES.pos_audited')
        },
        i18nPosRupture() {
            return this.getI18n(i18nKey, 'TITLES.pos_rupture')
        },
    },
    methods: {
        showModal(rupture, data, parameters) {
            this.additionalParameters = {
                ...parameters,
                product_id: data.id,
                rupture,
            }
            this.modal = data;
            this.submitLoading = false
            this.title = `${rupture ? this.i18nPosRupture : this.i18nPosAudited}: ${this.modal.name}`
            this.$refs.formModal.show()
        },

        fetch(url, params) {
            return RuptureService.fetchVuetable('general/listPosByProduct', params)
        },
    },
}
</script>
