<template>
    <div>
        <b-row>
            <b-col md="4">
                <indicator-card
                    ref="chartPosAudited"
                    :title="i18nPosAudited"
                    :busy="loadingResume"
                    :value="posAudited"
                />
            </b-col>
            <b-col md="4">
                <indicator-card
                    ref="chartPosVisited"
                    :title="i18nPosVisited"
                    :busy="loadingResume"
                    :value="posVisited"
                />
            </b-col>
            <b-col md="4">
                <indicator-card
                    ref="chartTotalBreaks"
                    :title="i18nTotalBreaks"
                    :busy="loadingResume"
                    :value="totalBreaks"
                />
            </b-col>
        </b-row>
        <b-row>
            <b-col md="12">
                <div class="card card-pdv">
                    <PageHeader :title="i18nChainBreak" />
                    <div class="card-body">
                        <api-mode-vuetable
                            ref="vuetable"
                            :fetch-data="fetch"
                            :fields="fields"
                            :per-page="perPage"
                            :additional-parameters="additionalParameters"
                            :sort-order="sortOrder">
                            <template slot="percentage" slot-scope="props">
                                {{ props.rowData.break_count_total > 0 ? parseFloat(props.rowData.break_count / props.rowData.break_count_total * 100).toFixed(2) : 0 }}%
                            </template>
                            <template slot="actions" slot-scope="props">
                                <div class="table-button-container">
                                    <more-table-button @onClick="openModalDetail(props.rowData)" />
                                </div>
                            </template>
                        </api-mode-vuetable>
                    </div>
                </div>
            </b-col>
        </b-row>
        <chain-break-detail-modal ref="chainBreakDetailModal" />
    </div>
</template>

<script>
import RuptureService from '@src/services/dashboards/widgets/RuptureService.js'

import ApiModeVuetable from '@/src/components/ApiModeVuetable.vue'
import PageHeader from '@/src/components/PageHeader.vue'
import IndicatorCard from '@/src/components/dashboards/IndicatorCard.vue'
import MoreTableButton from '@/src/components/MoreTableButton.vue'
import chainBreakDetailModal from '@/src/router/views/dashboards/outOfStock/chainBreakDetailModal.vue'
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'
import moment from 'moment';

const i18nKey = 'DASHBOARDS'

export default {
    components: {
        ApiModeVuetable,
        PageHeader,
        IndicatorCard,
        MoreTableButton,
        chainBreakDetailModal,
    },
    mixins: [
        validationMixin,
        swalFeedback,
    ],
    data() {
        return {
            i18nKey,
            perPage: 10,
            additionalParameters: {
                start: moment().startOf('day').add(-30, 'days').format('YYYY-MM-DD'),
                end: moment().startOf('day').format('YYYY-MM-DD'),
            },
            loadingResume: false,
            firstLoad: false,
            posAudited: 0,
            posVisited: 0,
            totalBreaks: 0,
            sortOrder: [
                {
                    field: 'name',
                    direction: 'asc',
                },
            ],
            fields: [
                {
                    name: 'name',
                    title: this.getI18n('POS', 'TITLES.chain'),
                },
                {
                    name: 'visits_count',
                    title: this.getI18n(i18nKey, 'TITLES.pos_visited'),
                },
                {
                    name: 'break_count',
                    title: this.getI18n(i18nKey, 'TITLES.total_breaks'),
                },
                {
                    name: 'percentage',
                    title: this.getI18n(i18nKey, 'TITLES.percentage_rupture'),
                },
                {
                    name: 'actions',
                    title: '',
                },
            ]
        }
    },
    validations: {},
    computed: {
        i18nPosAudited() {
            return this.getI18n(i18nKey, 'TITLES.pos_audited')
        },
        i18nPosVisited() {
            return this.getI18n(i18nKey, 'TITLES.pos_visited')
        },
        i18nTotalBreaks() {
            return this.getI18n(i18nKey, 'TITLES.total_breaks')
        },
        i18nChainBreak() {
            return this.getI18n(i18nKey, 'TITLES.chain_break')
        },
    },
    methods: {
        refresh(parameters) {
            this.additionalParameters = parameters
            this.refreshResume()
            this.refreshTable()
        },
        refreshResume(parameters) {
            if (parameters) {
                this.additionalParameters = parameters
            }

            this.loadingResume = true
            this.posAudited = 0
            this.posVisited = 0
            this.totalBreaks = 0

            RuptureService.chainResume(this.additionalParameters)
                .then((response) => {
                    this.posAudited = response.data.data.pos_count ?? 0
                    this.posVisited = response.data.data.visits_count ?? 0
                    this.totalBreaks = response.data.data.break_count ?? 0

                    this.loadingResume = false
                })
                .catch((err) => {
                    this.loadingResume = false
                    return err
                })
        },
        fetch(url, params) {
            if (!this.firstLoad) {
                this.firstLoad = true;
                return new Promise((resolve, reject) => {resolve([])});
            }

            return RuptureService.fetchVuetable('chain/listChain', params)
        },
        refreshTable() {
            this.$nextTick(() => this.$refs.vuetable.refresh())
        },
        openModalDetail(data) {
            this.$refs.chainBreakDetailModal.showModal(data, this.additionalParameters)
        }
    },
}
</script>
