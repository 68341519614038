<template>
    <div>
        <b-row class="justify-content-center">
            <lottie-player
                src="https://assets7.lottiefiles.com/packages/lf20_ufvrktsf.json"
                background="transparent"
                speed="1"
                style="width: 200px; height: 200px;"
                autoplay
                loop
            />
        </b-row>
    </div>
</template>

<script>
export default {
    props: {
        childClass: {
            type: String,
            required: false,
            default: ''
        },
        busy: {
            type: Boolean,
            required: false,
        },
        disabled: {
            type: Boolean,
            required: false,
        },
        title: {
            type: String,
            required: false,
            default: ''
        },
    },
    computed: {
        i18nNoRecordsFound() {
            return this.getI18n('PAGINATION', 'no_records_found')
        },
    },
}
</script>
