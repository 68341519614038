<script>
import vuetableFormatter from '@src/mixins/vuetableFormatters'

const i18nKey = 'PRODUCTS'
const i18nCommon = 'COMMON'

export default {
    mixins: [vuetableFormatter],
    data() {
        return {
            productChildrenFields: [
                {
                    key: 'reference',
                    label: this.getI18n(i18nKey, 'FIELDS.reference'),
                    sortable: true,
                },
                {
                    key: 'name',
                    label: this.getI18n(i18nCommon, 'name'),
                    sortable: true,
                },
                {
                    key: 'is_category_leader',
                    label: this.getI18n(i18nKey, 'FIELDS.category_leader'),
                    sortable: true,
                    formatter: this.booleanIcons,
                },
                {
                    key: 'is_competitor',
                    label: this.getI18n(i18nKey, 'FIELDS.competitor'),
                    sortable: true,
                    formatter: this.booleanIcons,
                },
            ],
        }
    },
}
</script>
