import BaseService from '@src/services/BaseService'

class RuptureService extends BaseService {
    constructor() {
        super('dashboards/widgets/rupture')
    }

    chainResume(params) {
        return this.axios.post(`${this.getUrl()}/chain/resume`, params)
    }

    generalPosMoreRupture(params) {
        return this.axios.post(`${this.getUrl()}/general/posMoreRupture`, params)
    }

    generalProductMoreRupture(params) {
        return this.axios.post(`${this.getUrl()}/general/productMoreRupture`, params)
    }

    generalProductCategory(params) {
        return this.axios.post(`${this.getUrl()}/general/productCategory`, params)
    }
}

export default new RuptureService()

