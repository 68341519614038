<template>
    <form-modal
        ref="formModal"
        :title="title"
        size="xl">
        <template v-slot:form>
            <form>
                <api-mode-vuetable
                    ref="vuetable"
                    :fetch-data="fetch"
                    :fields="fields"
                    :per-page="perPage"
                    :additional-parameters="additionalParameters"
                    :sort-order="sortOrder">
                    <template slot="percentage" slot-scope="props">
                        {{ modal.break_count_total > 0 ? parseFloat(props.rowData.break_count / modal.break_count_total * 100).toFixed(2) : 0 }}%
                    </template>
                </api-mode-vuetable>
            </form>
        </template>
    </form-modal>
</template>

<script>
import RuptureService from '@src/services/dashboards/widgets/RuptureService.js'

import FormModal from '@/src/components/FormModal.vue'
import ApiModeVuetable from '@/src/components/ApiModeVuetable.vue'
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'
import dueDateTypes from '@src/mixins/dueDateTypes'
import filterVuetable from '@src/mixins/filterVuetable'
import vuetableFormatter from '@src/mixins/vuetableFormatters'

const i18nKey = 'PRODUCTS'
const i18nCommon = 'COMMON'

export default {
    components: {
        FormModal,
        ApiModeVuetable,
    },
    mixins: [
        validationMixin,
        swalFeedback,
        dueDateTypes,
        filterVuetable,
        vuetableFormatter,
    ],
    data() {
        return {
            i18nKey,
            i18nCommon,
            perPage: 10,
            additionalParameters: {},
            modal: {},
            title: '',
            sortOrder: [
                {
                    field: 'name',
                    direction: 'asc',
                },
            ],
            fields: [
                {
                    name: 'reference',
                    title: this.getI18n(i18nKey, 'FIELDS.reference'),
                },
                {
                    name: 'name',
                    title: this.getI18n(i18nCommon, 'name'),
                },
                {
                    name: 'brand_name',
                    title: this.getI18n('BRANDS', 'TITLES.brand'),
                },
                {
                    name: 'category_name',
                    title: this.getI18n(i18nCommon, 'category'),
                },
                {
                    name: 'min_price',
                    title: this.getI18n(i18nKey, 'FIELDS.min_price'),
                    formatter: this.getI18nCurrency,
                },
                {
                    name: 'max_price',
                    title: this.getI18n(i18nKey, 'FIELDS.max_price'),
                    formatter: this.getI18nCurrency,
                },
                {
                    name: 'is_category_leader',
                    title: this.getI18n(i18nKey, 'FIELDS.category_leader'),
                    titleClass: 'text-center aligned',
                    dataClass: 'text-center aligned',
                    formatter: this.booleanIcons,
                },
            ]
        }
    },
    validations: {},
    computed: {
        i18nProductMix() {
            return this.getI18n('DASHBOARDS', 'TITLES.product_mix')
        },
        i18nProductInRupture() {
            return this.getI18n('DASHBOARDS', 'TITLES.product_in_rupture')
        },
    },
    methods: {
        showModal(rupture, data, parameters) {
            this.additionalParameters = {
                ...parameters,
                point_of_sale_id: data.id,
                rupture,
            }
            this.modal = data;
            this.submitLoading = false
            this.title = `${rupture ? this.i18nProductInRupture : this.i18nProductMix}: ${this.modal.name}`
            this.$refs.formModal.show()
        },

        fetch(url, params) {
            return RuptureService.fetchVuetable('general/listProductByPos', params)
        },
    },
}
</script>
