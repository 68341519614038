<template>
    <div class="card card-pdv">
        <PageHeader :title="title" :show-slot="displayMoreButton && !busy">
            <a v-if="displayMoreButton" href="javascript: void(0);" @click="handleModal">
                <i class="fe-more-horizontal icon-blue" />
            </a>
        </PageHeader>
        <div class="card-body">
            <b-row v-if="busy">
                <b-col md="6">
                    <b-skeleton type="avatar" class="doughnut-resume-chart"></b-skeleton>
                </b-col>
                <b-col md="6">
                    <div class="mt-2">
                        <b-skeleton />
                        <b-skeleton width="55%" />
                    </div>
                    <div class="mt-2">
                        <b-skeleton />
                        <b-skeleton width="55%" />
                    </div>
                    <div class="mt-2">
                        <b-skeleton />
                        <b-skeleton width="55%" />
                    </div>
                    <div class="mt-2">
                        <b-skeleton />
                        <b-skeleton width="55%" />
                    </div>
                </b-col>
            </b-row>
            <b-row v-else>
                <b-col md="6">
                    <doughnut-chart class="doughnut-resume-chart" :chart-data="chartData" />
                    <div class="donut-inner">
                        <label style="font-size: 30px">{{ percentage }}%</label>
                    </div>
                </b-col>
                <b-col md="6">
                    <div v-for="item in data" :key="item.title">
                        <div :class="`progress ${item.type === 'total' && data.length === 3 ? 'mt-4' : 'mt-2'}`">
                            <b-progress-bar
                                :value="item.type === 'total' ? records : item.value"
                                :max="totalRecords"
                                :style="`background-color: ${item.color}`"
                                class="progress-pdv">
                            </b-progress-bar>
                        </div>
                        {{ item.type === 'total' ? `${item.title}: ${records} ${getI18n('PAGINATION', 'of')} ${totalRecords}` : `${item.title}: ${item.value}` }}
                    </div>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import PageHeader from '@/src/components/PageHeader.vue'
import DoughnutChart from '@/src/components/charts/DoughnutChart.vue'
import { sumBy, map, max } from 'lodash'

export default {
    components: {
        PageHeader,
        DoughnutChart
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        busy: {
            type: Boolean,
            required: false,
        },
        displayMoreButton: {
            type: Boolean,
            required: false,
        }
    },
    data () {
      return {
        chartData: {},
        data: [],
        totalRecords: 0,
        records: 0,
        percentage: 0,
      }
    },
    methods: {
        handleModal() {
            if (this.busy) {
                return
            }

            this.$emit('onClick')
        },
        showChart(chart) {
            this.totalRecords = chart.data.find(item => item.type === 'total')?.value
            this.records = sumBy(chart.data.filter(item => item.type !== 'total'), 'value')
            this.percentage = this.totalRecords > 0 ? Math.round(sumBy(chart.data.filter(item => item.type === 'percentage'), 'value') / this.totalRecords * 100) : 0
            this.data = chart.data

            this.chartData = {
                labels: [],
                datasets: [
                    {
                        backgroundColor: map(chart.data, 'color'),
                        data: map(chart.data, 'value'),
                    }
                ]
            }

            if (this.chartData.datasets[0]) {
                if (this.chartData.datasets[0].data[0]) {
                    this.chartData.datasets[0].data[0] -= this.records
                }

                const backgroundColor = []
                const data = []

                for (let i = 0; i < this.chartData.datasets[0].data.length; i++) {
                    if (data.length <= 0 || this.chartData.datasets[0].data[i] > max(data)) {
                        backgroundColor.push(this.chartData.datasets[0].backgroundColor[i])
                        data.push(this.chartData.datasets[0].data[i])
                    }
                    else {
                        backgroundColor.unshift(this.chartData.datasets[0].backgroundColor[i])
                        data.unshift(this.chartData.datasets[0].data[i])
                    }
                }

                this.chartData.datasets[0] = {
                    backgroundColor,
                    data,
                }
            }
        }
    }
}
</script>
<style scoped>
.doughnut-resume-chart {
    width: 100%;
    height: 200px;
}

.donut-inner {
    width: 100%;
    height: 40px;
    position: absolute;
    top: 55%;
    left: 0;
    margin-top: -20px;
    line-height: 19px;
    text-align: center;
}
</style>
