<script>
  import { Doughnut } from 'vue-chartjs'

  export default {
    extends: Doughnut,
    props: {
        chartData: {
            type: Object,
            required: true,
        },
    },
    data () {
      return {
        options: {
            cutoutPercentage: 80,
            legend: {
                display: false,
            },
            tooltips: {
                enabled: false
            },
            responsive: true,
            maintainAspectRatio: false
        }
      }
    },
    mounted () {
        this.renderChart(this.chartData, this.options)
    }
  }
</script>
