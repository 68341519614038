<template>
    <div>
        <div
            v-infinite-scroll="refresh"
            infinite-scroll-disabled="disableInfiniteScroll"
            infinite-scroll-distance="limit"
            infinite-scroll-throttle-delay="300"
            >
            <div v-if="dataPagination.length > 0">
                <b-row v-for="pos in dataPagination" :key="pos.id">
                    <b-col md="12">
                        <div class="card card-pdv">
                            <PageHeader :title="pos.name" />
                            <div class="card-body">
                                <b-table-simple
                                    hover
                                    show-empty
                                    sticky-header="900px"
                                >
                                    <b-thead>
                                    <b-tr>
                                        <b-th
                                            sticky-column
                                            style="background-color: #fff;"
                                        >
                                            {{ i18nProduct }}
                                        </b-th>
                                        <b-th
                                            v-for="(item, index) in pos.products[0].items"
                                            :key="index"
                                            class="text-center"
                                            style="background-color: #fff;"
                                        >
                                        {{ formatDateShort(item.date) }}
                                        </b-th>
                                    </b-tr>
                                    </b-thead>
                                    <b-tbody>
                                    <b-tr v-for="product in pos.products" :key="product.id">
                                        <b-td
                                            sticky-column
                                            style="background-color: #fff;"
                                        >
                                            {{ product.name }}
                                        </b-td>
                                        <b-td
                                            v-for="(item, index) in product.items"
                                            :key="index"
                                            class="align-middle"
                                        >
                                            <div class="text-center">
                                                <i
                                                    v-if="item.value >= 1"
                                                    class="fe-x-circle icon-red icon-size-16 mr-1"
                                                    :title="i18nRupture"
                                                />
                                                <i
                                                    v-else-if="item.value === 0"
                                                    class="fe-check-circle icon-green icon-size-16 mr-1"
                                                    :title="i18nPresent"
                                                />
                                                <i
                                                    v-else
                                                    class="fe-circle icon-ligth icon-size-16 mr-1"
                                                    :title="i18nNotRated"
                                                />
                                            </div>
                                        </b-td>
                                    </b-tr>
                                    </b-tbody>
                                </b-table-simple>
                                <b-row class="d-flex justify-content-center">
                                    <div>
                                        <i class="fe-check-circle icon-green icon-size-16 mr-1"/>
                                        {{ i18nPresent }}
                                    </div>
                                    <div>
                                        <i class="fe-x-circle icon-red icon-size-16 ml-3 mr-1"/>
                                        {{ i18nRupture }}
                                    </div>
                                    <div>
                                        <i class="fe-circle icon-ligth icon-size-16 ml-3 mr-1"/>
                                        {{ i18nNotRated }}
                                    </div>
                                </b-row>
                                <b-row class="justify-content-end">
                                <b-pagination
                                    v-model="pos.currentPage"
                                    class="mr-2"
                                    :total-rows="pos.total"
                                    :per-page="productPerPage"
                                />
                                </b-row>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </div>
        </div>
        <div v-if="data.length <= 0 && !loading">
            <no-records-found />
        </div>
        <div v-if="currentPage >= lastPage && disableInfiniteScroll">
            <end-timeline />
        </div>
        <div v-if="loading">
            <b-row v-for="(index) in perPage" :key="index">
                <b-col md="12">
                    <div class="card card-pdv">
                        <PageHeader :title="i18nPos" />
                        <div class="card-body">
                            <b-skeleton-table
                                :rows="5"
                                :columns="10"
                                :table-props="{
                                    hover: true,
                                    noBorderCollapse: true,
                                }"
                            />
                        </div>
                    </div>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import RuptureService from '@src/services/dashboards/widgets/RuptureService.js'

import EndTimeline from '@/src/components/EndTimeline'
import NoRecordsFound from '@/src/components/NoRecordsFound'
import PageHeader from '@/src/components/PageHeader.vue'
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'
import vuetableFormatter from '@src/mixins/vuetableFormatters'
import filterVuetable from '@src/mixins/filterVuetable'
import moment from 'moment';
import cloneObject from '@/src/utils/clone-object'

const i18nKey = 'DASHBOARDS'
const i18nCommon = 'COMMON'

export default {
    components: {
        EndTimeline,
        NoRecordsFound,
        PageHeader,
    },
    mixins: [
        validationMixin,
        swalFeedback,
        vuetableFormatter,
        filterVuetable,
    ],
    data() {
        return {
            i18nKey,
            i18nCommon,
            loading: false,
            enableInfiniteScroll: false,
            lastPage: 999,
            currentPage: 1,
            perPage: 2,
            productPerPage: 5,
            totalItems: 0,
            inheritedAdditionalParameters: {},
            additionalParameters: {
                start: moment().startOf('day').add(-30, 'days').format('YYYY-MM-DD'),
                end: moment().startOf('day').format('YYYY-MM-DD'),
            },
            data: [],
            dataPagination: [],
            dataPaginationOld: [],
        }
    },
    validations: {},
    computed: {
        i18nPos() {
            return this.getI18n('POS', 'TITLES.pos_alias')
        },
        i18nProduct() {
            return this.getI18n('PRODUCTS', 'TITLES.product')
        },
        i18nPresent() {
            return this.getI18n(i18nCommon, 'present')
        },
        i18nRupture() {
            return this.getI18n(i18nCommon, 'rupture')
        },
        i18nNotRated() {
            return this.getI18n(i18nCommon, 'not_rated')
        },
        disableInfiniteScroll() {
            return !this.enableInfiniteScroll
        },
    },
    watch: {
        dataPagination: {
            deep: true,
            handler: function (newValue) {
                if (this.dataPaginationOld.length > 0) {
                    for (let i = 0; i < newValue.length; i++) {
                        if (this.dataPaginationOld[i] && newValue[i].currentPage !== this.dataPaginationOld[i].currentPage) {
                            const startIndex = (newValue[i].currentPage - 1) * this.productPerPage
                            const endIndex = newValue[i].currentPage * this.productPerPage
                            this.dataPagination[i].products = this.data[i].products.slice(startIndex, endIndex)
                        }
                    }
                }

                this.dataPaginationOld = cloneObject(this.dataPagination)
            },
        },
    },
    methods: {
        createAdditionalParameters() {
            return this.formatParameters({
                ...this.inheritedAdditionalParameters,
                per_page: this.perPage,
                page: this.currentPage,
                orderBy: 'name',
                sortedBy: "desc"
            })
        },
        filter() {
            this.additionalParameters = this.createAdditionalParameters()
            this.refresh()
        },
        refresh(parameters) {
            if (parameters) {
                this.enableInfiniteScroll = true
                this.lastPage = 999
                this.currentPage = 0
                this.data = []
                this.dataPagination = []
                this.dataPaginationOld = []
                this.inheritedAdditionalParameters = parameters
            }
            this.currentPage += 1

            if (this.currentPage > this.lastPage) {
                this.enableInfiniteScroll = false
                return
            }

            this.additionalParameters = this.createAdditionalParameters()

            this.loading = true

            RuptureService.fetchAll(this.additionalParameters, 'pos/list')
                .then((response) => {
                    Array.prototype.push.apply(this.data, response.data.data)

                    response.data.data.forEach(item => {
                      this.dataPagination.push({
                        id: item.id,
                        name: item.name,
                        products: item.products.slice(0, this.productPerPage),
                        currentPage: 1,
                        total: item.products.length,
                      })
                    });

                    this.lastPage = response.data.meta.last_page
                    this.totalItems = response.data.meta.total
                    this.perPage = response.data.meta.per_page

                    this.loading = false
                })
                .catch((err) => {
                    this.loading = false
                    return err
                })
        },
    },
}
</script>
