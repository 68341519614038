<template>
    <div>
        <b-row>
            <b-col md="6">
                <resume-chart-card
                    ref="chartPercentageRupturePerVisit"
                    :title="i18nPercentageRupture"
                    :busy="loadingResume" />
            </b-col>
            <!-- <b-col md="4">

            </b-col> -->
            <b-col md="6">
                <div class="card card-pdv" style="min-height: 91.8%;">
                    <PageHeader :title="posMoreRupture.title" />
                    <div class="card-body">
                        <b-row v-if="loadingPosMoreRupture">
                            <b-col md="12">
                                <b-skeleton />
                                <b-skeleton />
                                <b-skeleton />
                            </b-col>
                        </b-row>
                        <b-row v-else>
                            <b-col md="12">
                                <div class="progress">
                                    <b-progress-bar
                                        :value="posMoreRupture.value"
                                        :max="resumeRupture.total_rupture"
                                        style="background-color: #CE0404"
                                        class="progress-pdv">
                                    </b-progress-bar>
                                </div>
                                {{ `${i18nPosBreak}: ${posMoreRupture.value} (${posMoreRupture.percentage}% ${i18nOfTheTotalRuptures})` }}
                            </b-col>
                        </b-row>
                    </div>
                    <PageHeader class="mt-2" :title="productMoreRupture.title" />
                    <div class="card-body">
                        <b-row v-if="loadingProductMoreRupture">
                            <b-col md="12">
                                <b-skeleton />
                                <b-skeleton />
                                <b-skeleton />
                            </b-col>
                        </b-row>
                        <b-row v-else>
                            <b-col md="12">
                                <div class="progress">
                                    <b-progress-bar
                                        :value="productMoreRupture.value"
                                        :max="resumeRupture.total_rupture"
                                        style="background-color: #CE0404"
                                        class="progress-pdv">
                                    </b-progress-bar>
                                </div>
                                {{ `${i18nProductBreak}: ${productMoreRupture.value} (${productMoreRupture.percentage}% ${i18nOfTheTotalRuptures})` }}
                            </b-col>
                        </b-row>
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="12">
                <div class="card card-pdv">
                    <PageHeader :title="i18nRuptureByCategory" />
                    <div class="card-body">
                        <column-chart
                            :series-prop="seriesCategory"
                            :categories-series-prop="categoriesCategory"
                            :hide-legend="true"
                            :busy="loadingCategory"
                        />
                    </div>
                </div>
            </b-col>
            <!-- <b-col md="6">
                <div class="card card-pdv">
                    <PageHeader :title="i18nRuptureBySaleExecutive" />
                    <div class="card-body">
                        <column-chart
                            :series-prop="seriesSaleExecutive"
                            :categories-series-prop="categoriesSaleExecutive"
                            :busy="loadingSaleExecutive"
                        />
                    </div>
                </div>
            </b-col> -->
        </b-row>
        <b-row>
            <b-col md="12">
                <div class="card card-pdv">
                    <PageHeader :title="i18nLastWeekInformation" />
                    <div class="card-body">
                        <api-mode-vuetable
                            ref="vuetablePos"
                            :fetch-data="fetchPos"
                            :fields="fieldsPos"
                            :per-page="perPage"
                            :additional-parameters="additionalParameters">
                            <template slot="product_count" slot-scope="props">
                                <count-table-button
                                    :value="props.rowData.product_count"
                                    @onClick="openProductMixModal(props.rowData)"
                                />
                            </template>
                            <template slot="product_rupture_count" slot-scope="props">
                                <count-table-button
                                    :value="props.rowData.product_rupture_count"
                                    @onClick="openProductRuptureModal(props.rowData)"
                                />
                            </template>
                        </api-mode-vuetable>
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="12">
                <div class="card card-pdv">
                    <PageHeader :title="i18nRuptureIndexByProductRecentAudit" />
                    <div class="card-body">
                        <api-mode-vuetable
                            ref="vuetableProduct"
                            :fetch-data="fetchProduct"
                            :fields="fieldsProduct"
                            :per-page="perPage"
                            :additional-parameters="additionalParameters">
                            <template slot="pos_count" slot-scope="props">
                                <count-table-button
                                    :value="props.rowData.pos_count"
                                    @onClick="openPosAuditedModal(props.rowData)"
                                />
                            </template>
                            <template slot="pos_break_count" slot-scope="props">
                                <count-table-button
                                    :value="props.rowData.pos_break_count"
                                    @onClick="openPosRuptureModal(props.rowData)"
                                />
                            </template>
                        </api-mode-vuetable>
                    </div>
                </div>
            </b-col>
        </b-row>
        <grid-form-modal
            ref="gridMixModal"
            :modal="modal"
            :items="modal.mixes"
            :fields="posChildrenFields"
            :title="i18nMix"
        />
        <grid-form-modal
            ref="gridProductModal"
            :modal="modal"
            :items="modal.products"
            :fields="posChildrenFields"
            :title="i18nProductInRupture"
        />
        <general-break-pos-detail-modal ref="generalBreakPosDetailModal" />
        <general-break-product-detail-modal ref="generalBreakProductDetailModal" />
    </div>
</template>

<script>
import RuptureService from '@src/services/dashboards/widgets/RuptureService.js'

import ApiModeVuetable from '@/src/components/ApiModeVuetable.vue'
import ResumeChartCard from '@/src/components/dashboards/ResumeChartCard.vue'
import PageHeader from '@/src/components/PageHeader.vue'
import ColumnChart from '@/src/components/charts/ColumnChart.vue'
import CountTableButton from '@/src/components/CountTableButton.vue'
import GridFormModal from '@src/components/GridFormModal.vue'
import generalBreakPosDetailModal from '@/src/router/views/dashboards/outOfStock/generalBreakPosDetailModal.vue'
import generalBreakProductDetailModal from '@/src/router/views/dashboards/outOfStock/generalBreakProductDetailModal.vue'
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'
import vuetableFormatters from '@src/mixins/vuetableFormatters.vue'
import posChildrenFields from '@/src/fields/childrens/posChildrenFields.vue'
import productChildrenFields from '@/src/fields/childrens/productChildrenFields.vue'
import { map } from 'lodash'
import moment from 'moment';

const i18nKey = 'DASHBOARDS'

export default {
    components: {
        ApiModeVuetable,
        ResumeChartCard,
        PageHeader,
        ColumnChart,
        CountTableButton,
        GridFormModal,
        generalBreakPosDetailModal,
        generalBreakProductDetailModal,
    },
    mixins: [
        validationMixin,
        swalFeedback,
        vuetableFormatters,
        posChildrenFields,
        productChildrenFields,
    ],
    props: {
        date: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            i18nKey,
            modal: {
                mixes: [],
                products: [],
            },
            perPage: 10,
            additionalParameters: {
                start: moment().startOf('day').add(-30, 'days').format('YYYY-MM-DD'),
                end: moment().startOf('day').format('YYYY-MM-DD'),
            },
            loadingResume: false,
            loadingPosMoreRupture: false,
            loadingProductMoreRupture: false,
            loadingCategory: false,
            loadingSaleExecutive: false,
            seriesCategory: [],
            seriesSaleExecutive: [],
            categoriesCategory: [],
            categoriesSaleExecutive: [],
            resumeRupture: {
                total_visits: 0,
                total_rupture: 0,
                total_no_rupture: 0,
            },
            productMoreRupture: {
                title: this.getI18n('PRODUCTS', 'TITLES.product'),
                value: 0,
                percentage: 0,
            },
            posMoreRupture: {
                title: this.getI18n('POS', 'TITLES.pos_alias'),
                value: 0,
                percentage: 0,
            },
            fieldsPos: [
                {
                    name: 'name',
                    title: this.getI18n('POS', 'TITLES.pos'),
                },
                {
                    name: 'product_count',
                    title: this.getI18n(i18nKey, 'TITLES.product_mix'),
                },
                {
                    name: 'product_rupture_count',
                    title: this.getI18n(i18nKey, 'TITLES.product_in_rupture'),
                },
                {
                    name: 'percentage_rupture',
                    title: this.getI18n(i18nKey, 'TITLES.percentage_rupture'),
                    formatter: this.formatPercentageInteger,
                },
                {
                    name: 'date_last_visit',
                    title: this.getI18n('COMMON', 'date'),
                    formatter: this.formatDateTime
                },
            ],
            fieldsProduct: [
                {
                    name: 'name',
                    title: this.getI18n('PRODUCTS', 'TITLES.product'),
                },
                {
                    name: 'pos_count',
                    title: this.getI18n(i18nKey, 'TITLES.pos_audited'),
                },
                {
                    name: 'pos_break_count',
                    title: this.getI18n(i18nKey, 'TITLES.pos_rupture'),
                },
                {
                    name: 'percentage_rupture',
                    title: this.getI18n(i18nKey, 'TITLES.percentage_rupture'),
                    formatter: this.formatPercentageInteger,
                },
            ],
        }
    },
    validations: {},
    computed: {
        i18nPercentageRupture() {
            return this.getI18n(i18nKey, 'TITLES.percentage_rupture')
        },
        i18nRuptureByCategory() {
            return this.getI18n(i18nKey, 'TITLES.rupture_by_category')
        },
        i18nRuptureBySaleExecutive() {
            return this.getI18n(i18nKey, 'TITLES.rupture_by_sale_executive')
        },
        i18nLastWeekInformation() {
            return this.getI18n(i18nKey, 'TITLES.last_week_information')
        },
        i18nRuptureIndexByProductRecentAudit() {
            return this.getI18n(i18nKey, 'TITLES.rupture_index_by_product_recent_audit')
        },
        i18nMix() {
            return this.getI18n('MIXES', 'TITLES.mix')
        },
        i18nPosAudited() {
            return this.getI18n(i18nKey, 'TITLES.pos_audited')
        },
        i18nPosBreak() {
            return this.getI18n(i18nKey, 'TITLES.pos_break')
        },
        i18nProductBreak() {
            return this.getI18n(i18nKey, 'TITLES.product_break')
        },
        i18nPosRupture() {
            return this.getI18n(i18nKey, 'TITLES.pos_rupture')
        },
        i18nProductInRupture() {
            return this.getI18n(i18nKey, 'TITLES.product_in_rupture')
        },
        i18nOfTheTotalRuptures() {
            return this.getI18n(i18nKey, 'TITLES.of_the_total_ruptures')
        },
    },
    methods: {
        refresh(parameters) {
            this.additionalParameters = parameters
            this.refreshResume()
            this.refreshRuptureByCategory()
            this.refreshRuptureBySaleExecutive()
            this.refreshTablePos()
            this.refreshTableProduct()
        },
        refreshResume() {
            this.loadingResume = true
            this.loadingPosMoreRupture = true
            this.loadingProductMoreRupture = true

            this.resumeRupture = {
                total_visits: 0,
                total_rupture: 0,
                total_no_rupture: 0,
            }

            this.productMoreRupture = {
                title: this.getI18n('PRODUCTS', 'TITLES.product'),
                value: 0,
                percentage: 0,
            }

            this.posMoreRupture = {
                title: this.getI18n('POS', 'TITLES.pos_alias'),
                value: 0,
                percentage: 0,
            }

            RuptureService.chainResume(this.additionalParameters)
                .then((response) => {
                    const { data } = response.data

                    this.resumeRupture = {
                        total_visits: data.answer_count,
                        total_rupture: data.break_count,
                        total_no_rupture: data.answer_count - data.break_count,
                    }

                    const chartData =
                        {
                            data: [
                                {
                                    value: this.resumeRupture.total_visits,
                                    color: '#5899EF',
                                    type: 'total',
                                    title: this.getI18n('PAGINATION', 'total'),
                                },
                                {
                                    value: this.resumeRupture.total_no_rupture,
                                    color: '#89E09C',
                                    type: null,
                                    title: this.getI18n(i18nKey, 'TITLES.no_rupture'),
                                },
                                {
                                    value: this.resumeRupture.total_rupture,
                                    color: '#CE0404',
                                    type: 'percentage',
                                    title: this.getI18n(i18nKey, 'TITLES.with_rupture'),
                                },
                            ],
                        }

                    this.$refs.chartPercentageRupturePerVisit.showChart(chartData)

                    RuptureService.generalPosMoreRupture(this.additionalParameters)
                        .then((response) => {
                            if (response.data) {
                                this.posMoreRupture = {
                                    title: `${this.getI18n('POS', 'TITLES.pos_alias')}: ${response.data.name}`,
                                    value: response.data.break_count,
                                    percentage: this.resumeRupture.total_rupture > 0 ? parseFloat(response.data.break_count / this.resumeRupture.total_rupture * 100).toFixed(2) : 0,
                                }
                            }

                            this.loadingPosMoreRupture = false
                        })
                        .catch((err) => {
                            this.loadingPosMoreRupture = false
                            return err
                        })

                    RuptureService.generalProductMoreRupture(this.additionalParameters)
                        .then((response) => {
                            if (response.data) {
                                this.productMoreRupture = {
                                    title: `${this.getI18n('PRODUCTS', 'TITLES.product')}: ${response.data.name}`,
                                    value: response.data.break_count,
                                    percentage: this.resumeRupture.total_rupture > 0 ? parseFloat(response.data.break_count / this.resumeRupture.total_rupture * 100).toFixed(2) : 0,
                                }
                            }

                            this.loadingProductMoreRupture = false
                        })
                        .catch((err) => {
                            this.loadingProductMoreRupture = false
                            return err
                        })

                    this.loadingResume = false
                })
                .catch((err) => {
                    this.loadingResume = false
                    return err
                })
        },
        refreshRuptureByCategory() {
            this.loadingCategory = true
            this.seriesCategory = []
            this.categoriesCategory = []

            RuptureService.generalProductCategory(this.additionalParameters)
                .then((response) => {
                    this.categoriesCategory = map(response.data, 'name')

                    this.seriesCategory.push({
                        name: 'Total',
                        data: map(response.data, 'break_count')
                    })

                    this.loadingCategory = false
                })
                .catch((err) => {
                    this.loadingCategory = false
                    return err
                })
        },
        refreshRuptureBySaleExecutive(){
            // this.loadingSaleExecutive = true

            // this.seriesSaleExecutive = [
            //     {
            //         name: 'Fulano 1',
            //         data: [3]
            //     },
            //     {
            //         name: 'Fulano 2',
            //         data: [54]
            //     },
            //     {
            //         name: 'Fulano 3',
            //         data: [20]
            //     },
            //     {
            //         name: 'Fulano 4',
            //         data: [17]
            //     },
            // ]
            // this.categoriesSaleExecutive = [`${moment(this.date.start).format('DD/MM/YYYY')} - ${moment(this.date.end).format('DD/MM/YYYY')}`]

            // this.loadingSaleExecutive = false
        },
        fetchPos(url, params) {
            return RuptureService.fetchVuetable('general/listPos', params)
        },
        fetchProduct(url, params) {
            return RuptureService.fetchVuetable('general/listProduct', params)
        },
        refreshTablePos() {
            this.$nextTick(() => this.$refs.vuetablePos.refresh())
        },
        refreshTableProduct() {
            this.$nextTick(() => this.$refs.vuetableProduct.refresh())
        },
        openModalDetail(data) {
            this.$refs.chainBreakDetailModal.showModal(data)
        },
        openProductMixModal(data) {
            this.$refs.generalBreakProductDetailModal.showModal(false, data, this.additionalParameters)
        },
        openProductRuptureModal(data) {
            this.$refs.generalBreakProductDetailModal.showModal(true, data, this.additionalParameters)
        },
        openPosAuditedModal(data) {
            this.$refs.generalBreakPosDetailModal.showModal(false, data, this.additionalParameters)
        },
        openPosRuptureModal(data) {
            this.$refs.generalBreakPosDetailModal.showModal(true, data, this.additionalParameters)
        },
    },
}
</script>
