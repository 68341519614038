<template>
    <Layout>
       <div class="col-12">
            <Breadcrumb :items="items" />
            <div class="row mt-3 mb-3">
                <div class="col-sm-8">
                    <b-row>
                        <b-col md="5">
                            <b-form-group
                                :label="i18nPeriod"
                                class="required label-pdv"
                                label-for="filter-date"
                            >
                                <date-range-picker
                                    :value.sync="filters.date"
                                    :max-date="new Date()"
                                    :disabled="loading"
                                    :disable-clean-button="true"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group
                                :label="i18nRegional"
                                class="label-pdv"
                                label-for="filter-regional"
                            >
                                <multiSelectWithService
                                    :id="'filter-regional'"
                                    ref="regionalUnitMultiselect"
                                    v-model="filters.regionals"
                                    :service="'pos-regionals'"
                                    :searchable="true"
                                    :multiple="true"
                                    :parameters="filterParameters"
                                    :disabled="loading"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
                <div class="col-sm-4 align-self-center">
                    <div class="d-flex justify-content-between">
                        <more-filters-label-button class="col-sm-6" :disabled="loading" @onClick="showFilterModal" />
                        <filter-button class="col-sm-6 col-md-6" :disabled="loading" @onClick="filter" />
                    </div>
                </div>
            </div>
            <b-tabs
                id="out-of-stock-dashboard-tabs"
                v-model="tabIndex"
                class="tabs-pdv"
                @activate-tab="tabActivated"
            >
                <b-tab :title="i18nChainBreak">
                    <chain-break-tab
                        ref="chainBreakTab"
                    />
                </b-tab>
                <b-tab :title="i18nGeneralBreak">
                    <general-break-tab
                        ref="generalBreakTab"
                        :date="filters.date"
                    />
                </b-tab>
                <b-tab :title="i18nPosBreak">
                    <pos-break-tab ref="posBreakTab" />
                </b-tab>
            </b-tabs>
       </div>
        <out-of-stock-filter-modal ref="outOfStockFilterModal" :handle-submit="filter" />
    </Layout>
</template>

<script>
import Layout from '@layouts/main'
import Breadcrumb from '@/src/components/Breadcrumb.vue'
import MoreFiltersLabelButton from '@/src/components/MoreFiltersLabelButton'
import FilterButton from '@/src/components/FilterButton'
import DateRangePicker from '@/src/components/DateRangePicker'
import multiSelectWithService from '@src/components/multiSelectWithService'
import outOfStockFilterModal from '@/src/router/views/dashboards/outOfStock/outOfStockFilterModal.vue'
import chainBreakTab from '@/src/router/views/dashboards/outOfStock/chainBreakTab.vue'
import generalBreakTab from '@/src/router/views/dashboards/outOfStock/generalBreakTab.vue'
import posBreakTab from '@/src/router/views/dashboards/outOfStock/posBreakTab.vue'
import filterVuetable from '@src/mixins/filterVuetable'
import moment from 'moment';

const i18nKey = 'DASHBOARDS'
const i18nCommon = 'COMMON'

export default {
    components: {
        Layout,
        Breadcrumb,
        MoreFiltersLabelButton,
        FilterButton,
        DateRangePicker,
        multiSelectWithService,
        outOfStockFilterModal,
        chainBreakTab,
        generalBreakTab,
        posBreakTab,
    },
    mixins: [filterVuetable],
    data() {
        return {
            i18nKey,
            i18nCommon,
            loading: false,
            tabChainBreakLoaded: false,
            tabGeneralBreakLoaded: false,
            tabPosBreakLoaded: false,
            tabIndex: 0,
            additionalParameters: this.createAdditionalParameters(),
            filterParameters: {
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
            },
            filters: {
                date: {
                    start: moment().startOf('day').add(-30, 'days').toDate(),
                    end: moment().startOf('day').toDate()
                },
                business_units: [],
                regionals: [],
                chains: [],
                channels: [],
                flags: [],
                categories: [],
            },
        }
    },
    computed: {
        items() {
            return [
                {
                    text: 'Home',
                    href: '/',
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.dashboards',
                        modifier: 2,
                    }),
                    active: true,
                },
                {
                    text: this.getI18n(i18nKey, 'TITLES.out_of_stock_dashboard'),
                    active: true,
                },
            ]
        },
        i18nPeriod() {
            return this.getI18n(i18nCommon, 'period')
        },
        i18nRegional() {
            return this.getI18n('POS', 'TITLES.regional')
        },
        i18nChainBreak() {
            return this.getI18n(i18nKey, 'TITLES.chain_break')
        },
        i18nGeneralBreak() {
            return this.getI18n(i18nKey, 'TITLES.general_break')
        },
        i18nPosBreak() {
            return this.getI18n(i18nKey, 'TITLES.pos_break')
        },
    },
    methods: {
        tabActivated(newTabIndex, oldTabIndex) {
            if (newTabIndex !== oldTabIndex) {
                this.tabIndex = newTabIndex
                this.refresh()
            }
        },
        createAdditionalParameters() {
            if (!this.filters) {
                this.filters = {
                    date: {
                        start: moment().startOf('day').add(-30, 'days').toDate(),
                        end: moment().startOf('day').toDate()
                    },
                    business_units: [],
                    regionals: [],
                    chains: [],
                    channels: [],
                    flags: [],
                    categories: [],
                }
            }

            const period = this.formatFilterDate(this.filters.date)
            return this.formatParameters({
                business_unit_id: this.filters.business_units.map((businessUnit) => businessUnit.id),
                regional_id: this.filters.regionals.map((regional) => regional.id),
                channel_id: this.filters.channels.map((channel) => channel.id),
                chain_id: this.filters.chains.map((chain) => chain.id),
                flag_id: this.filters.flags.map((flag) => flag.id),
                product_category_id: this.filters.categories,
                start: period.from,
                end: period.to,
            })
        },
        filter(appliedFilters) {
            if (appliedFilters) {
                this.filters = appliedFilters
            }
            this.additionalParameters = this.createAdditionalParameters()
            this.tabChainBreakLoaded = false
            this.tabGeneralBreakLoaded = false
            this.tabPosBreakLoaded = false
            this.refresh()
        },
        showFilterModal() {
            this.$nextTick(() => this.$refs.outOfStockFilterModal.showModal(this.filters))
        },
        refresh() {
            this.loading = true

            if (!this.tabChainBreakLoaded && this.tabIndex === 0) {
                this.tabChainBreakLoaded = true
                this.$refs.chainBreakTab.refresh(this.additionalParameters)
            }
            if (!this.tabGeneralBreakLoaded && this.tabIndex === 1) {
                this.tabGeneralBreakLoaded = true
                this.$refs.generalBreakTab.refresh(this.additionalParameters)
            }
            if (!this.tabPosBreakLoaded && this.tabIndex === 2) {
                this.tabPosBreakLoaded = true
                this.$refs.posBreakTab.refresh(this.additionalParameters)
            }

            this.loading = false
        },
    }
}
</script>
